.header-logo-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.sider-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.sider-change-language-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}


.sider-connect-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-button-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}